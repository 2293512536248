import React from "react";
import { Slide } from "react-awesome-reveal";
import FeatureCard from "./FeatureCard";
import CardCoin from "../assets/card-coin.svg";
import CardTick from "../assets/card-tick.svg";
import EmptyWalletTick from "../assets/empty-wallet-tick.svg";
import StickyNote from "../assets/stickynote.svg";

const LoanFeatureSection = () => {
  return (
    <div className="mt-28 px-4 sm:px-10">
      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-col gap-4 items-center justify-start mb-5 relative">
          <div
            className="text-foundation-grey-grey-500 text-center font-['ReemKufiInk-Bold',_sans-serif] text-3xl md:text-4xl md:leading-[57.6px] font-bold relative"
            style={{ letterSpacing: "0.01em" }}
          >
            Loan Money Effortlessly with PayM8: Anytime, Anywhere
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 items-start">
          <Slide direction="up" triggerOnce duration={1000} delay={100}>
            <FeatureCard
              icon={EmptyWalletTick}
              title="Accessible to All"
              description="Free for Everyone"
              isFirst={true}
            />
          </Slide>
          <Slide direction="up" triggerOnce duration={1000} delay={200}>
            <FeatureCard
              icon={CardCoin}
              title="Instant Loans"
              description="Money in Minutes"
            />
          </Slide>
          <Slide direction="up" triggerOnce duration={1000} delay={300}>
            <FeatureCard
              icon={CardTick}
              title="Quick Payments"
              description="Pay for Services in Seconds"
            />
          </Slide>
          <Slide direction="up" triggerOnce duration={1000} delay={400}>
            <FeatureCard
              icon={StickyNote}
              title="Scheduled Transfers"
              description="Manage Financial Arrangements Regularly"
            />
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default LoanFeatureSection;
