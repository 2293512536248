import React from "react";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
import appStore from "../assets/app-store.svg";
import googlePlay from "../assets/google-play.svg";
import FacebookIcon from "../assets/Facebook.svg";
import InstagramIcon from "../assets/Instagram.svg";
import PaymateLogoDark from "../assets/payment-logo-dark.svg";
import LocationMinus from "../assets/location-minus.svg";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path, section) => {
    if (window.location.pathname === path) {
      scroller.scrollTo(section, {
        smooth: true,
        offset: -50,
        duration: 500,
      });
    } else {
      navigate(path, { state: { scrollToSection: section } });
    }
  };

  return (
    <div className="bg-foundation-grey-grey-500 px-4 sm:px-10 mt-12">
      <div className="max-w-7xl w-full mx-auto py-6">
        <div className="flex flex-row flex-wrap gap-24 items-center justify-center lg:justify-between shrink-0 w-full relative">
          <div className="flex flex-col gap-12 w-auto lg:w-2/3">
            <div className="flex flex-col gap-6">
              <div className="w-[104.82px] h-[46px]">
                <a className="flex items-baseline" href="/">
                  <img
                    src={PaymateLogoDark}
                    alt="logo"
                    className="w-auto h-full"
                  />
                </a>
              </div>
              <div className="text-neutral-or-tertiary-white text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal">
                PayM8: Simplifying money management with friends and family, all
                in one secure platform.
              </div>
            </div>
            <div className="flex flex-row gap-12 items-start justify-start flex-wrap">
              <div
                className="text-foundation-grey-grey-200 text-left font-['ReemKufi-Medium',_sans-serif] text-base leading-6 font-medium relative cursor-pointer"
                onClick={() => handleNavigation("/", "home")}
              >
                Home
              </div>
              <div
                className="text-foundation-grey-grey-200 text-left font-['ReemKufi-Medium',_sans-serif] text-base leading-6 font-medium relative cursor-pointer"
                onClick={() => handleNavigation("/", "how-it-works")}
              >
                How it works?
              </div>
              <div
                className="text-foundation-grey-grey-200 text-left font-['ReemKufi-Medium',_sans-serif] text-base leading-6 font-medium relative cursor-pointer"
                onClick={() => handleNavigation("/", "features-description")}
              >
                3-N-1 One Stop
              </div>
              <div
                className="text-foundation-grey-grey-200 text-left font-['ReemKufi-Medium',_sans-serif] text-base leading-6 font-medium relative cursor-pointer"
                onClick={() => handleNavigation("/", "contact")}
              >
                Contact
              </div>
              <div
                className="text-foundation-grey-grey-200 text-left font-['ReemKufi-Medium',_sans-serif] text-base leading-6 font-medium relative cursor-pointer"
                onClick={() => handleNavigation("/privacy-policy", "top")}
              >
                Privacy Policy
              </div>
              <div
                className="text-foundation-grey-grey-200 text-left font-['ReemKufi-Medium',_sans-serif] text-base leading-6 font-medium relative cursor-pointer"
                onClick={() => handleNavigation("/terms-conditions", "top")}
              >
                Terms and Condition
              </div>
            </div>
            <div className="text-foundation-grey-grey-200 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal">
              <div className="flex">
                <img src={LocationMinus} alt="location"></img>
                <span className="ml-3">
                  PayM8 Services, Inc.6465 Oakhurst PI Fairburn, Georgia 30213
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row lg:flex-col gap-[17.47px] items-start justify-start shrink-0 relative">
            <button className="flex items-center transition-all font-semibold rounded-md">
              <img src={appStore} alt="appStore" />
            </button>
            <button className="bg-transparent flex items-center transition-all font-semibold rounded-md">
              <img src={googlePlay} alt="googlePlay" />
            </button>
          </div>
        </div>
        <hr className="hidden sm:block my-12"></hr>
        <div className="flex flex-col sm:flex-row gap-10 items-center justify-center sm:justify-between shrink-0 w-full mt-10 sm:mt-0 relative">
          <div className="text-neutral-or-tertiary-white text-center sm:text-left font-['ReemKufi-Medium',_sans-serif] text-base leading-6 font-medium absolute left-0 right-0 bottom-0 sm:relative self-stretch">
            © 2024 PayM8. All rights reserved.
          </div>
          <div className="flex flex-row gap-[34px] items-center justify-center sm:justify-start self-stretch shrink-0 relative mb-12 sm:mb-0">
            <a
              href="https://www.facebook.com/share/1ALFjooeEByvPfyc/?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FacebookIcon} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/paym8_services?igsh=MWQ1aWs0MHlsN21maw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={InstagramIcon} alt="instagram"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
