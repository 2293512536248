import React from "react";

const FeatureCard = ({ icon, title, description, isFirst }) => {
  return (
    <div
      className={`mx-auto h-64 rounded-[10px] pt-10 pr-2.5 pb-5 pl-2.5 flex flex-col gap-2 items-center justify-start shrink-0 w-full relative ${
        isFirst
          ? "bg-[#ffffff] shadow-md border border-solid border-foundation-grey-grey-50"
          : ""
      }`}
      style={{
        boxShadow: isFirst ? "0px 6px 20px 0px rgba(0, 0, 0, 0.15)" : "none",
      }}
    >
      <div className="shrink-0 w-16 h-16 relative">
        <img src={icon} alt="FeatureIcon" />
      </div>
      <div className="text-foundation-grey-grey-500 text-center font-['ReemKufiInk-Bold',_sans-serif] text-2xl leading-[60px] font-bold relative self-stretch">
        {title}
      </div>
      <div className="text-foundation-grey-grey-300 text-center font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[30px] font-normal relative self-stretch">
        {description}
        <br />
      </div>
    </div>
  );
};

export default FeatureCard;
