import React from "react";
import { Slide } from "react-awesome-reveal";
import PersonalLoanImg from "../assets/financial-services1.svg";
import ServiceInvoiceImg from "../assets/financial-services2.svg";
import FinancialArrangementImg from "../assets/financial-services3.svg";

const FeaturesDescription = () => {
  return (
    <div className="px-4 sm:px-10 mt-28">
      <div className="max-w-7xl w-full mx-auto">
        {/* Section Header */}
        <div className="flex flex-col gap-1.5 items-start justify-start mb-5 relative">
          <div
            className="bg-foundation-green-green-100 rounded-[10px] pt-1.5 pr-2 pb-1.5 pl-2 flex flex-row flex-wrap gap-0.5 items-center justify-center shrink-0 w-[330px] relative overflow-hidden"
            style={{ boxShadow: "0px 1px 2px 0px rgba(105, 81, 255, 0.05)" }}
          >
            <div className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-6 font-normal relative">
              3-N-1 One Stop Shop for Financial Services
            </div>
          </div>
          <div
            className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Bold',_sans-serif] text-3xl md:text-4xl md:leading-[57.6px] font-bold relative self-stretch"
            style={{ letterSpacing: "0.01em" }}
          >
            Store Active and Completed Records for Payments History Regarding
            Each Loan Number.
          </div>
        </div>

        {/* Personal Loan Generation Section */}
        <Slide direction="up" triggerOnce duration={1000}>
          <div className="grid md:grid-cols-2 items-center gap-10 mb-8">
            <img
              className="rounded-[10px] border-solid border-foundation-grey-grey-200 border h-full relative"
              style={{
                background: "linear-gradient(to left, #d9d9d9, #d9d9d9)",
                objectFit: "cover",
              }}
              src={PersonalLoanImg}
              alt="Personal Loan Generation"
            />
            <div className="flex flex-col gap-[29px] items-start justify-start shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Bold',_sans-serif] text-2xl leading-6 font-bold relative self-stretch">
                Personal Loan Generation
              </div>
              <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Sending/Receiving Personal Loans to Friends/Family/Associates.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Allowing Users to Select Loan Terms, including Loan Amount,
                  Option to Add Interest to Loan, Repayment Term Length, and
                  Providing Proof of Loan Terms Agreement via Digital Signatures
                  Required by Each Lender and Loan Recipient to Officially
                  Establish Each Loan.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Each Lender and Loan Recipient Will Have the Option to Loan
                  Funds and Repay Loans to App Users via Send/Transmit Payment
                  Option Incorporated on the App.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Creating Records/Ledgers for Active Personal Loans and Loan
                  History/Archive for Previous Loans Repaid in Full.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Proof of Payment is Recorded Automatically upon Determination
                  that Funds Have Been Received by Each Lender’s Account.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Store Active and Completed Records for Payments History
                  Regarding Each Loan Number.
                </li>
              </ul>
            </div>
          </div>
        </Slide>

        {/* Service Invoices Section */}
        <Slide direction="up" triggerOnce duration={1000}>
          <div className="grid md:grid-cols-2 items-center gap-10 mb-8">
            <div className="flex flex-col gap-[29px] items-start justify-start shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Bold',_sans-serif] text-2xl leading-6 font-bold relative self-stretch">
                Service Invoices
              </div>
              <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Generating Records for Services and Contractual Work.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Services Can Range from as Basic as a Playful Business Theme
                  for Paying Children for Doing Chores to Serious Contracted
                  Services.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Each Client/Parent may Pay for Services to Parties Performing
                  Services via Send/Transmit Payment Option Incorporated on the
                  App.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Proof of Payment is Recorded Automatically upon Determination
                  that Funds Have Been Received by the Party Performing each
                  Service’s Account.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Store Active and Archive Records for Active and Completed
                  Services and Recorded Payments.
                </li>
              </ul>
            </div>
            <img
              className="rounded-[10px] border-solid border-foundation-grey-grey-200 border shrink-0 h-full relative"
              style={{
                background: "linear-gradient(to left, #d9d9d9, #d9d9d9)",
                objectFit: "cover",
              }}
              src={ServiceInvoiceImg}
              alt="Service Invoices"
            />
          </div>
        </Slide>

        {/* Financial Arrangements Section */}
        <Slide direction="up" triggerOnce duration={1000}>
          <div className="grid md:grid-cols-2 items-center gap-10">
            <img
              className="rounded-[10px] border-solid border-foundation-grey-grey-200 border shrink-0 h-full relative"
              style={{
                background: "linear-gradient(to left, #d9d9d9, #d9d9d9)",
                objectFit: "cover",
              }}
              src={FinancialArrangementImg}
              alt="Financial Arrangements"
            />
            <div className="flex flex-col gap-[29px] items-start justify-start shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Bold',_sans-serif] text-2xl leading-6 font-bold relative self-stretch">
                Financial Arrangements
              </div>
              <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Establish Periodic Financial Support to Other Parties.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Financial Support Including Child Support, Sponsoring
                  Lifestyles, Periodic Support for Friends/Family/Significant
                  Others.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Option for Child Support Payers and Recipients to Store
                  Records and Establish Proof (via Digital Signature
                  Input/Confirmation from each Party) that Payments are not
                  Gifts and Intended Solely Towards Support of Child/Children.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Creating Records/Ledgers for Active Personal Loans and Loan
                  History/Archive for Previous Loans Repaid in Full.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Proof of Payment is Recorded Automatically upon Determination
                  that Funds Have Been Received by the Party Receiving Financial
                  Support.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Each Sponsoring User may Periodically Send Funds to Parties
                  Being Sponsored via Send/Transmit Payment Option Incorporated
                  on the App.
                </li>
                <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                  Store Active and Archive Records for Each Financial
                  Arrangement.
                </li>
              </ul>
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default FeaturesDescription;
