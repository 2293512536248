import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

const TermsConditions = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      scroller.scrollTo(location.state.scrollToSection, {
        smooth: true,
        offset: -50,
        duration: 500,
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="pt-12 px-4 sm:px-10 gradient" id="top">
      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-col gap-[63px] items-center justify-start relative">
          <div className="bg-foundation-green-green-50 rounded-[15px] pt-[47px] pb-[47px] flex flex-col gap-6 items-center justify-start self-stretch shrink-0 relative">
            <div className="flex flex-col gap-3 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-green-green-700 text-center font-['ReemKufi-SemiBold',_sans-serif] text-base leading-6 font-semibold relative self-stretch">
                Current as of August 23, 2024
              </div>
              <div
                className="text-foundation-grey-grey-500 text-center font-['ReemKufi-Bold',_sans-serif] text-4xl md:text-5xl leading-[60px] font-bold relative self-stretch"
                style={{ letterSpacing: "-0.02em" }}
              >
                Terms and Conditions
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-12 items-start justify-start shrink-0 relative">
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Agreement between User and PayM8Services.com
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  Welcome to PayM8Services.com. The PayM8Services.com website
                  (the "Site") is comprised of various web pages operated by
                  PayMate Services ("PayM8"). PayM8Services.com is offered to
                  you conditioned on your acceptance without modification of the
                  terms, conditions, and notices contained herein (the "Terms").
                  Your use of PayM8Services.com constitutes your agreement to
                  all such Terms. Please read these terms carefully, and keep a
                  copy of them for your reference
                </p>
                <p className="mb-4">PayM8Services.com is an E-Commerce Site.</p>
                <div>
                  <div className="mb-4">
                    <h1>PayM8:</h1>
                    <h2>3-N-1 One Stop Shop for Financial Services</h2>
                  </div>
                  <h3 className="mb-4">Services Provided:</h3>
                  <div>
                    <div className="mb-4">
                      <h4 className="mb-3">Personal Loan Generation:</h4>
                      <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                        <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                          Sending/Receiving Personal Loans to
                          Friends/Family/Associates
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h4 className="mb-3">Service Invoices:</h4>
                      <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                        <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                          Generating Records for Services and Contractual Work
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h4 className="mb-3">Financial Arrangements:</h4>
                      <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                        <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                          Establish Periodic Financial Support to Other Parties
                        </li>
                        <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                          Financial Support Including Child Support, Sponsoring
                          Lifestyles, Periodic Support for
                          Friends/Family/Significant Others
                        </li>
                        <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                          Option for Child Support Payers and Recipients to
                          Store Records and Establish Proof (via Digital
                          Signature Input/Confirmation from each Party) that
                          Payments are not Gifts and Intended Solely Towards
                          support of the child/children in question.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Privacy
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  Your use of PayM8Services.com is subject to PayM8's Privacy
                  Policy. Please review our Privacy Policy, which also governs
                  the Site and informs users of our data collection practices.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Electronic Communications
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  Visiting PayM8Services.com or sending emails to PayM8
                  constitutes electronic communications. You consent to receive
                  electronic communications and you agree that all agreements,
                  notices, disclosures and other communications that we provide
                  to you electronically, via email and on the Site, satisfy any
                  legal requirement that such communications be in writing.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Your Account
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  If you use this site, you are responsible for maintaining the
                  confidentiality of your account and password and for
                  restricting access to your computer, and you agree to accept
                  responsibility for all activities that occur under your
                  account or password. You may not assign or otherwise transfer
                  your account to any other person or entity. You acknowledge
                  that PayM8 is not responsible for third party access to your
                  account that results from theft or misappropriation of your
                  account. PayM8 and its associates reserve the right to refuse
                  or cancel service, terminate accounts, or remove or edit
                  content in our sole discretion.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Children Under Thirteen
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 does not knowingly collect, either online or offline,
                  personal information from persons under the age of thirteen.
                  If you are under 18, you may use PayM8Services.com only with
                  permission of a parent or guardian.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Links to Third Party Sites/Third Party Services
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8Services.com may contain links to other websites ("Linked
                  Sites"). The Linked Sites are not under the control of PayM8
                  and PayM8 is not responsible for the contents of any Linked
                  Site, including without limitation any link contained in a
                  Linked Site, or any changes or updates to a Linked Site. PayM8
                  is providing these links to you only as a convenience, and the
                  inclusion of any link does not imply endorsement by PayM8 of
                  the site or any association with its operators.
                </p>
                <p className="mb-4">
                  Certain services made available via PayM8Services.com are
                  delivered by third party sites and organizations. By using any
                  product, service or functionality originating from the
                  PayM8Services.com domain, you hereby acknowledge and consent
                  that PayM8 may share such information and data with any third
                  party with whom PayM8 has a contractual relationship to
                  provide the requested product, service or functionality on
                  behalf of PayM8Services.com users and customers.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                No Unlawful or Prohibited Use/Intellectual Property
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  You are granted a non-exclusive, non-transferable, revocable
                  license to access and use PayM8Services.com strictly in
                  accordance with these terms of use. As a condition of your use
                  of the Site, you warrant to PayM8 that you will not use the
                  Site for any purpose that is unlawful or prohibited by these
                  Terms. You may not use the Site in any manner which could
                  damage, disable, overburden, or impair the Site or interfere
                  with any other party's use and enjoyment of the Site. You may
                  not obtain or attempt to obtain any materials or information
                  through any means not intentionally made available or provided
                  for through the Site.
                </p>
                <p className="mb-4">
                  All content included as part of the Service, such as text,
                  graphics, logos, images, as well as the compilation thereof,
                  and any software used on the Site, is the property of PayM8 or
                  its suppliers and protected by copyright and other laws that
                  protect intellectual property and proprietary rights. You
                  agree to observe and abide by all copyright and other
                  proprietary notices, legends or other restrictions contained
                  in any such content and will not make any changes thereto.
                </p>
                <p className="mb-4">
                  You will not modify, publish, transmit, reverse engineer,
                  participate in the transfer or sale, create derivative works,
                  or in any way exploit any of the content, in whole or in part,
                  found on the Site. PayM8 content is not for resale. Your use
                  of the Site does not entitle you to make any unauthorized use
                  of any protected content, and in particular you will not
                  delete or alter any proprietary rights or attribution notices
                  in any content. You will use protected content solely for your
                  personal use, and will make no other use of the content
                  without the express written permission of PayM8 and the
                  copyright owner. You agree that you do not acquire any
                  ownership rights in any protected content. We do not grant you
                  any licenses, express or implied, to the intellectual property
                  of PayM8 or our licensors except as expressly authorized by
                  these Terms.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Third Party Accounts
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  You will be able to connect your PayM8 account to third party
                  accounts. By connecting your PayM8 account to your third party
                  account, you acknowledge and agree that you are consenting to
                  the continuous release of information about you to others (in
                  accordance with your privacy settings on those third party
                  sites). If you do not want information about you to be shared
                  in this manner, do not use this feature.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                International Users
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  The Service is controlled, operated and administered by PayM8
                  from our offices within the USA. If you access the Service
                  from a location outside the USA, you are responsible for
                  compliance with all local laws. You agree that you will not
                  use the PayM8 Content accessed through PayM8Services.com in
                  any country or in any manner prohibited by any applicable
                  laws, restrictions or regulations.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Indemnification
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  You agree to indemnify, defend and hold harmless PayM8, its
                  officers, directors, employees, agents and third parties, for
                  any losses, costs, liabilities and expenses (including
                  reasonable attorney's fees) relating to or arising out of your
                  use of or inability to use the Site or services, any user
                  postings made by you, your violation of any terms of this
                  Agreement or your violation of any rights of a third party, or
                  your violation of any applicable laws, rules or regulations.
                  PayM8 reserves the right, at its own cost, to assume the
                  exclusive defense and control of any matter otherwise subject
                  to indemnification by you, in which event you will fully
                  cooperate with PayM8 in asserting any available defenses.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Class Action Waiver
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  Any arbitration under these Terms and Conditions will take
                  place on an individual basis; class arbitrations and
                  class/representative/collective actions are not permitted. THE
                  PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER
                  ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
                  CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                  REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
                  ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless
                  both you and PayM8 agree otherwise, the arbitrator may not
                  consolidate more than one person's claims, and may not
                  otherwise preside over any form of a representative or class
                  proceeding.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Liability Disclaimer
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN
                  OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
                  TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                  INFORMATION HEREIN. PAYMATE SERVICES AND/OR ITS SUPPLIERS MAY
                  MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
                </p>

                <p className="mb-4">
                  PAYMATE SERVICES AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS
                  ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS,
                  AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES
                  AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO
                  THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
                  INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                  ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY
                  KIND. PAYMATE SERVICES AND/OR ITS SUPPLIERS HEREBY DISCLAIM
                  ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
                  SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
                  ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                </p>

                <p className="mb-4">
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL PAYMATE SERVICES AND/OR ITS SUPPLIERS BE LIABLE FOR ANY
                  DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                  DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
                  LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING
                  OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
                  THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR
                  RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
                  SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES
                  AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE
                  ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT,
                  TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
                  PAYMATE SERVICES OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF
                  THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS
                  DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
                  CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY
                  NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF
                  THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND
                  EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Termination/Access Restriction
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 reserves the right, in its sole discretion, to terminate
                  your access to the Site and the related services or any
                  portion thereof at any time, without notice. To the maximum
                  extent permitted by law, this agreement is governed by the
                  laws of the State of Georgia and you hereby consent to the
                  exclusive jurisdiction and venue of courts in Georgia in all
                  disputes arising out of or relating to the use of the Site.
                  Use of the Site is unauthorized in any jurisdiction that does
                  not give effect to all provisions of these Terms, including,
                  without limitation, this section.
                </p>
                <p className="mb-4">
                  You agree that no joint venture, partnership, employment, or
                  agency relationship exists between you and PayM8 as a result
                  of this agreement or use of the Site. PayM8's performance of
                  this agreement is subject to existing laws and legal process,
                  and nothing contained in this agreement is in derogation of
                  PayM8's right to comply with governmental, court and law
                  enforcement requests or requirements relating to your use of
                  the Site or information provided to or gathered by PayM8 with
                  respect to such use. If any part of this agreement is
                  determined to be invalid or unenforceable pursuant to
                  applicable law including, but not limited to, the warranty
                  disclaimers and liability limitations set forth above, then
                  the invalid or unenforceable provision will be deemed
                  superseded by a valid, enforceable provision that most closely
                  matches the intent of the original provision and the remainder
                  of the agreement shall continue in effect.
                </p>
                <p className="mb-4">
                  Unless otherwise specified herein, this agreement constitutes
                  the entire agreement between the user and PayM8 with respect
                  to the Site and it supersedes all prior or contemporaneous
                  communications and proposals, whether electronic, oral or
                  written, between the user and PayM8 with respect to the Site.
                  A printed version of this agreement and of any notice given in
                  electronic form shall be admissible in judicial or
                  administrative proceedings based upon or relating to this
                  agreement to the same extent and subject to the same
                  conditions as other business documents and records originally
                  generated and maintained in printed form. It is the express
                  wish to the parties that this agreement and all related
                  documents be written in English.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Changes to Terms
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 reserves the right, in its sole discretion, to change
                  the Terms under which PayM8Services.com is offered. The most
                  current version of the Terms will supersede all previous
                  versions. PayM8 encourages you to periodically review the
                  Terms to stay informed of our updates.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Contact Us
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 welcomes your questions or comments regarding the Terms:
                </p>
                <p className="mb-4">
                  <div className="mb-4 flex">
                    <span className="text-foundation-grey-grey-500 font-semibold">
                      Email Address:
                    </span>
                    <p className="ml-3">PayMate2024@gmail.com</p>
                  </div>
                  <div className="mb-4 flex">
                    <span className="text-foundation-grey-grey-500 font-semibold">
                      Telephone Number:
                    </span>
                    <p className="ml-3">3015188280</p>
                  </div>
                  <div>
                    <span className="text-foundation-grey-grey-500 font-semibold">
                      Mailing Address:
                    </span>
                    <p className="flex flex-col">
                      <p>PayM8 Services, Inc.</p>
                      <p>6465 Oakhurst Pl</p>
                      <p>Fairburn, Georgia</p>
                      <p>30213</p>
                    </p>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
