import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import { Element } from "react-scroll";
import Description from "./Description";
import Features from "./Features";
import HowItWorks from "./HowItWorks";
import DownloadAppSection from "./DownloadApp";
import FeaturesDescription from "./FeaturesDescription";
import FAQs from "./FAQs";
import Contact from "./Contact";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      scroller.scrollTo(location.state.scrollToSection, {
        smooth: true,
        offset: -50,
        duration: 500,
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <div className="gradient">
        <Element name="home" className="element">
          <Description />
        </Element>
        <Features />
        <Element name="how-it-works" className="element">
          <HowItWorks />
        </Element>
      </div>
      <DownloadAppSection />
      <Element name="features-description" className="element">
        <FeaturesDescription />
      </Element>
      <FAQs />
      <Element name="contact" className="element">
        <Contact />
      </Element>
    </>
  );
};

export default Home;
