import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

const PrivacyPolicy = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      scroller.scrollTo(location.state.scrollToSection, {
        smooth: true,
        offset: -50,
        duration: 500,
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="pt-12 px-4 sm:px-10 gradient" id="top">
      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-col gap-[63px] items-center justify-start relative">
          <div className="bg-foundation-green-green-50 rounded-[15px] pt-[47px] pb-[47px] flex flex-col gap-6 items-center justify-start self-stretch shrink-0 relative">
            <div className="flex flex-col gap-3 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-green-green-700 text-center font-['ReemKufi-SemiBold',_sans-serif] text-base leading-6 font-semibold relative self-stretch">
                Current as of August 23, 2024
              </div>
              <div
                className="text-foundation-grey-grey-500 text-center font-['ReemKufi-Bold',_sans-serif] text-4xl md:text-5xl leading-[60px] font-bold relative self-stretch"
                style={{ letterSpacing: "-0.02em" }}
              >
                ONLINE PRIVACY POLICY AGREEMENT
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-12 items-start justify-start shrink-0 relative">
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 Services, Inc. (PayM8) values its users' privacy. This
                  Privacy Policy ("Policy") will help you understand how we
                  collect and use personal information from those who visit our
                  website or make use of our online facilities and services, and
                  what we will and will not do with the information we collect.
                  Our Policy has been designed and created to ensure those
                  affiliated with PayM8 Services, Inc. of our commitment and
                  realization of our obligation not only to meet, but to exceed,
                  most existing privacy standards.
                </p>
                <p className="mb-4">
                  We reserve the right to make changes to this Policy at any
                  given time. If you want to make sure that you are up to date
                  with the latest changes, we advise you to frequently visit
                  this page. If at any point in time PayM8 Services, Inc.
                  decides to make use of any personally identifiable information
                  on file, in a manner vastly different from that which was
                  stated when this information was initially collected, the user
                  or users shall be promptly notified by email. Users at that
                  time shall have the option as to whether to permit the use of
                  their information in this separate manner.
                </p>
                <p className="mb-4">
                  This Policy applies to PayM8 Services, Inc., and it governs
                  any and all data collection and usage by us. Through the use
                  of www.PayM8Services.com, you are therefore consenting to the
                  data collection procedures expressed in this Policy.
                </p>
                <p className="mb-4">
                  Please note that this Policy does not govern the collection
                  and use of information by companies that PayM8 Services, Inc.
                  does not control, nor by individuals not employed or managed
                  by us. If you visit a website that we mention or link to, be
                  sure to review its privacy policy before providing the site
                  with information. It is highly recommended and suggested that
                  you review the privacy policies and statements of any website
                  you choose to use or frequent to better understand the way in
                  which websites garner, make use of and share the information
                  collected.
                </p>
                <p className="mb-4">
                  Specifically, this Policy will inform you of the following
                </p>
                <ol class="space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                  <li>
                    <span>
                      What personally identifiable information is collected from
                      you through our website.
                    </span>
                  </li>
                  <li>
                    <span>
                      Why we collect personally identifiable information and the
                      legal basis for such collection.
                    </span>
                  </li>
                  <li>
                    <span>
                      How we use the collected information and with whom it may
                      be shared.
                    </span>
                  </li>
                  <li>
                    <span>
                      What choices are available to you regarding the use of
                      your data; and
                    </span>
                  </li>
                  <li>
                    <span>
                      The security procedures in place to protect the misuse of
                      your information.
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Information we collect
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  It is always up to you whether to disclose personally
                  identifiable information to us, although if you elect not to
                  do so, we reserve the right not to register you as a user or
                  provide you with any products or services.
                </p>
                <p className="mb-4">
                  In addition, PayM8 Services, Inc. may have the occasion to
                  collect non-personal anonymous demographic information, such
                  as age, gender, household income, political affiliation, race
                  and religion, as well as the type of browser you are using, IP
                  address, or type of operating system, which will assist us in
                  providing and maintaining superior quality service.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Why We Collect Information and For How Long
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  We are collecting your data for several reasons:
                </p>
                <p className="mb-4">
                  <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      To better understand your needs and provide you with the
                      services you have requested;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      To fulfill our legitimate interest in improving our
                      services and products;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      To send you promotional emails containing information we
                      think you may like when we have your consent to do so;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      To contact you to fill out surveys or participate in other
                      types of market research, when we have your consent to do
                      so;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      To customize our website according to your online behavior
                      and personal preferences.
                    </li>
                  </ul>
                </p>
                <p className="mb-4">
                  The data we collect from you will be stored for no longer than
                  necessary. The length of time we retain said information will
                  be determined based upon the following criteria: the length of
                  time your personal information remains relevant; the length of
                  time it is reasonable to keep records to demonstrate that we
                  have fulfilled our duties and obligations; any limitation
                  periods within which claims might be made; any retention
                  periods prescribed by law or recommended by regulators,
                  professional bodies or associations; the type of contract we
                  have with you, the existence of your consent, and our
                  legitimate interest in keeping such information as stated in
                  this Policy.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Use of Information Collected
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 Services, Inc. does not now, nor will it in the future,
                  sell, rent or lease any of its customer lists and/or names to
                  any third parties.
                </p>
                <p className="mb-4">
                  PayM8 Services, Inc. may collect and may make use of personal
                  information to assist in the operation of our website and to
                  ensure delivery of the services you need and request. At
                  times, we may find it necessary to use personally identifiable
                  information as a means to keep you informed of other possible
                  products and/or services that may be available to you from
                  www.PayM8Services.com
                </p>
                <p className="mb-4">
                  PayM8 Services, Inc. may also be in contact with you with
                  regards to completing surveys and/ or research questionnaires
                  related to your opinion of current or potential future
                  services that may be offered.
                </p>
                <p className="mb-4">
                  PayM8 Services, Inc. uses various third-party social media
                  features including but not limited to Facebook, Twitter,
                  Instagram, LinkedIn and other interactive programs. These may
                  collect your IP address and require cookies to work properly.
                  These services are governed by the privacy policies of the
                  providers and are not within PayM8 Services, Inc.'s control.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Disclosure of Information
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 Services, Inc. may not use or disclose the information
                  provided by you except under the following circumstances:
                </p>
                <p className="mb-4">
                  <ul className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      As necessary to provide services or products you have
                      ordered;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      In other ways described in this Policy or to which you
                      have otherwise consented;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      In the aggregate with other information in such a way so
                      that your identity cannot reasonably be determined;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      As required by law, or in response to a subpoena or search
                      warrant;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      To outside auditors who have agreed to keep the
                      information confidential;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      As necessary to enforce the Terms of Service;
                    </li>
                    <li className="relative mb-4 pl-6 before:absolute before:left-0 before:top-2 before:w-1 before:h-1 before:bg-slate-500 before:rounded-full before:content-['']">
                      As necessary to maintain, safeguard, and preserve all the
                      rights and property of PayM8 Services, Inc.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Non-Marketing Purposes
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 Services, Inc. greatly respects your privacy. We do
                  maintain and reserve the right to contact you if needed for
                  non-marketing purposes (such as bug alerts, security breaches,
                  account issues, and/or changes in PayM8 Services, Inc.
                  products and services). In certain circumstances, we may use
                  our website, newspapers, or other public means to post a
                  notice.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Children under the age of 13
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 Services, Inc.'s website is not directed to, and does
                  not knowingly collect personal identifiable information from,
                  children under the age of thirteen (13). If it is determined
                  that such information has been inadvertently collected on
                  anyone under the age of thirteen (13), we shall immediately
                  take the necessary steps to ensure that such information is
                  deleted from our system's database, or in the alternative,
                  that verifiable parental consent is obtained for the use and
                  storage of such information. Anyone under the age of thirteen
                  (13) must seek and obtain parent or guardian permission to use
                  this website.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Unsubscribe or Opt-Out
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  All users and visitors to our website have the option to
                  discontinue receiving communications from us by way of email
                  or newsletters. To discontinue or unsubscribe from our website
                  please send an email that you wish to unsubscribe to
                  PayM8Services.com. If you wish to unsubscribe or opt-out from
                  any third-party websites, you must go to that specific website
                  to unsubscribe or opt-out. PayM8 Services, Inc. will continue
                  to adhere to this Policy with respect to any personal
                  information previously collected.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Links to Other Websites
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  Our website does contain links to affiliate and other
                  websites. PayM8 Services, Inc. does not claim nor accept
                  responsibility for any privacy policies, practices and/or
                  procedures of other such websites. Therefore, we encourage all
                  users and visitors to be aware when they leave our website and
                  to read the privacy statements of every website that collects
                  personally identifiable information. This Privacy Policy
                  Agreement applies only and solely to the information collected
                  by our website.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Notice to European Union Users
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 Services, Inc.'s operations are located primarily in the
                  United States. If you provide information to us, the
                  information will be transferred out of the European Union (EU)
                  and sent to the United States. (The adequacy decision on the
                  EU-US Privacy became operational on August 1, 2016. This
                  framework protects the fundamental rights of anyone in the EU
                  whose personal data is transferred to the United States for
                  commercial purposes. It allows the free transfer of data to
                  companies that are certified in the US under the Privacy
                  Shield.) By providing personal information to us, you are
                  consenting to its storage and use as described in this Policy.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Security
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  PayM8 Services, Inc. takes precautions to protect your
                  information. When you submit sensitive information via the
                  website, your information is protected both online and
                  offline. Wherever we collect sensitive information (e.g.
                  credit card information), that information is encrypted and
                  transmitted to us in a secure way. You can verify this by
                  looking for a lock icon in the address bar and looking for
                  "https" at the beginning of the address of the webpage.
                </p>
                <p className="mb-4">
                  While we use encryption to protect sensitive information
                  transmitted online, we also protect your information offline.
                  Only employees who need the information to perform a specific
                  job (for example, billing or customer service) are granted
                  access to personally identifiable information. The computers
                  and servers in which we store personally identifiable
                  information are kept in a secure environment. This is all done
                  to prevent any loss, misuse, unauthorized access, disclosure
                  or modification of the user's personal information under our
                  control.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                Acceptance of Terms
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  By using this website, you are hereby accepting the terms and
                  conditions stipulated within the Privacy Policy Agreement. If
                  you are not in agreement with our terms and conditions, then
                  you should refrain from further use of our sites. In addition,
                  your continued use of our website following the posting of any
                  updates or changes to our terms and conditions shall mean that
                  you agree and acceptance of such changes.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
              <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-SemiBold',_sans-serif] text-3xl leading-[38px] font-semibold relative self-stretch">
                How to Contact Us
              </div>
              <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-lg leading-7 font-normal relative self-stretch">
                <p className="mb-4">
                  If you have any questions or concerns regarding the Privacy
                  Policy Agreement related to our website, please feel free to
                  contact us at the following email, telephone number or mailing
                  address
                </p>
                <p className="mb-4">
                  <div className="mb-4 flex">
                    <span className="text-foundation-grey-grey-500 font-semibold">
                      Email:
                    </span>
                    <p className="ml-3">PayMate2024@gmail.com</p>
                  </div>
                  <div className="mb-4 flex">
                    <span className="text-foundation-grey-grey-500 font-semibold">
                      Telephone Number:
                    </span>
                    <p className="ml-3">3015188280</p>
                  </div>
                  <div>
                    <span className="text-foundation-grey-grey-500 font-semibold">
                      Mailing Address:
                    </span>
                    <p className="flex flex-col">
                      <p>PayM8 Services, Inc.</p>
                      <p>6465 Oakhurst Pl</p>
                      <p>Fairburn, Georgia</p>
                      <p>30213</p>
                    </p>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
