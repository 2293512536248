import React, { useState } from "react";
import { Slide } from "react-awesome-reveal";

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "1. What is PayM8?",
      answer:
        "PayM8 is a mobile app that simplifies financial transactions between friends and family, allowing you to lend money, pay for services, and manage scheduled transfers with ease.",
    },
    {
      question: "2. How do I get started with PayM8?",
      answer:
        "Download the PayM8 app from the App Store or Google Play, create an account, and start managing your financial transactions effortlessly.",
    },
    {
      question: "3. Is PayM8 free to use?",
      answer:
        "Yes, PayM8 is completely free to download and use. There are no subscription fees or hidden charges.",
    },
    {
      question: "4. How do I lend money to a friend or family member?",
      answer:
        "Simply select the 'Create New Loan”' option in the app, enter the email address of the person you want to lend to, enter the amount, and confirm the transaction. You can also create a record or contract for the loan.",
    },
    {
      question: "5. How do I track the money I've lent or borrowed?",
      answer:
        "PayM8 keeps a record of all your transactions, so you can easily track what you’ve lent, borrowed, or paid for over time.",
    },
    {
      question: "6. Can I use PayM8 to pay for services?",
      answer:
        "Yes, PayM8 allows you to pay for services directly through the app, with an option to generate invoices and keep records.",
    },
    {
      question: "7. What happens if I forget my PayM8 password?",
      answer:
        "You can reset your password by selecting the 'Forgot Password' option on the login screen and following the instructions.",
    },
    {
      question: "8. Who can I contact for support with PayM8?",
      answer:
        "If you need help or have questions, you can reach out to our support team via the provided email and phone number",
    },
  ];

  return (
    <div className="px-4 sm:px-10 mt-28 pt-20 pb-16 bg-[#f5f9ed]">
      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-col gap-[25px] items-start justify-start relative mb-14">
          <div
            className="bg-foundation-green-green-100 rounded-[10px] pt-1.5 pr-2 pb-1.5 pl-2 flex flex-row gap-0.5 items-center justify-center shrink-0 w-[52px] h-[22px] relative overflow-hidden"
            style={{ boxShadow: "0px 1px 2px 0px rgba(105, 81, 255, 0.05)" }}
          >
            <div className="text-foundation-grey-grey-500 text-center font-['ReemKufi-Medium',_sans-serif] text-xs leading-[18px] font-medium relative">
              FAQ
            </div>
          </div>
          <div className="flex flex-col gap-[25px] items-start justify-start self-stretch shrink-0 relative">
            <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-Medium',_sans-serif] text-3xl md:text-4xl font-medium relative">
              Frequently Asked Questions
            </div>
            <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Regular',_sans-serif] text-xl leading-6 font-normal relative">
              PayM8 is the only platform that allows you to manage your finances
              seamlessly, all in one place, across all your digital channels.
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
          {faqs.map((faq, index) => (
            <Slide
              direction="up"
              triggerOnce
              duration={500}
              delay={index * 100}
              key={index}
              className="w-full"
            >
              <div
                className="bg-[#ffffff] rounded-[10px] border-solid border-foundation-grey-grey-50 border p-[30px] flex flex-row gap-[30px] items-center justify-start self-stretch shrink-0 relative overflow-hidden cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <div className="flex flex-row gap-[63px] items-start justify-start flex-1 relative">
                  <div className="flex flex-col gap-7 items-start justify-start flex-1 relative">
                    <div className="text-foundation-grey-grey-600 text-left font-['ReemKufi-SemiBold',_sans-serif] text-xl leading-6 font-semibold relative self-stretch">
                      {faq.question}
                    </div>
                    {openIndex === index && (
                      <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Medium',_sans-serif] text-lg leading-[21.6px] font-medium relative self-stretch opacity-100">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                </div>
                <svg
                  className="shrink-0 w-6 h-6 relative overflow-visible"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform:
                      openIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <path
                    d="M11.29 15.8687C11.3851 15.9597 11.4972 16.0311 11.62 16.0787C11.8635 16.1787 12.1365 16.1787 12.38 16.0787C12.5028 16.0311 12.6149 15.9597 12.71 15.8687L15.71 12.8687C15.8983 12.6804 16.0041 12.425 16.0041 12.1587C16.0041 11.8924 15.8983 11.637 15.71 11.4487C15.5217 11.2604 15.2663 11.1546 15 11.1546C14.7337 11.1546 14.4783 11.2604 14.29 11.4487L13 12.7487L13 9.1587C13 8.89348 12.8946 8.63913 12.7071 8.45159C12.5196 8.26405 12.2652 8.1587 12 8.1587C11.7348 8.1587 11.4804 8.26405 11.2929 8.45159C11.1054 8.63913 11 8.89348 11 9.1587L11 12.7487L9.71 11.4487C9.61704 11.355 9.50644 11.2806 9.38458 11.2298C9.26272 11.179 9.13201 11.1529 9 11.1529C8.86799 11.1529 8.73728 11.179 8.61542 11.2298C8.49356 11.2806 8.38296 11.355 8.29 11.4487C8.19627 11.5417 8.12188 11.6523 8.07111 11.7741C8.02034 11.896 7.9942 12.0267 7.9942 12.1587C7.9942 12.2907 8.02034 12.4214 8.07111 12.5433C8.12188 12.6651 8.19627 12.7757 8.29 12.8687L11.29 15.8687Z"
                    fill="#8B8C8E"
                  />
                </svg>
              </div>
            </Slide>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
