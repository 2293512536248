import React from "react";

const HowItWorksStep = ({ imageSrc, stepNumber, title, description }) => {
  return (
    <div className="flex flex-col gap-[15px] mx-auto relative">
      <img
        className="rounded-[10px] border-solid border-foundation-grey-grey-200 border self-stretch shrink-0 h-[203px] relative"
        style={{ objectFit: "cover" }}
        src={imageSrc}
        alt={title}
      />
      <div className="bg-foundation-green-green-200 rounded-3xl pt-3 pb-3 flex flex-col gap-2.5 items-center justify-center shrink-0 w-12 h-12 relative">
        <div className="text-foundation-grey-grey-500 text-center font-['ReemKufiInk-Regular',_sans-serif] text-base leading-6 font-normal relative">
          {stepNumber}
        </div>
      </div>
      <div className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Bold',_sans-serif] text-2xl leading-6 font-bold relative self-stretch">
        {title}
      </div>
      <div className="text-foundation-grey-grey-300 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-[19.2px] font-normal relative self-stretch">
        {description}
      </div>
    </div>
  );
};

export default HowItWorksStep;
