import React from "react";
import { Slide } from "react-awesome-reveal";
import EmailIcon from "../assets/email-icon.svg";
import PhoneIcon from "../assets/phone-icon.svg";
import SocialIcon from "../assets/socials-icon.svg";
import FacebookIcon from "../assets/Facebook.svg";
import InstagramIcon from "../assets/Instagram.svg";

// Reusable Card Component
const ContactCard = ({ icon, title, content }) => {
  return (
    <div className="bg-white rounded-[10px] p-5 flex flex-col gap-2.5 items-start justify-start flex-1 h-[188px] relative">
      <div className="shrink-0 w-12 h-12 relative">
        <div className="rounded-[50%] w-12 h-12 absolute left-0 top-0">
          {icon}
        </div>
      </div>
      <div className="text-foundation-grey-grey-500 text-left font-['ReemKufi-Bold',_sans-serif] text-2xl md:text-3xl md:leading-[38px] font-bold relative self-stretch">
        {title}
      </div>
      <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Medium',_sans-serif] text-xl leading-[30px] font-medium relative self-stretch">
        {content}
      </div>
    </div>
  );
};

// ContactSection Component
const ContactSection = () => {
  return (
    <div className="px-4 sm:px-10 mt-28">
      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-col gap-12 md:gap-16 mb-8 items-start justify-start self-stretch shrink-0 relative">
          <ContactHeader />
          <Slide direction="up" triggerOnce duration={1000} className="w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 w-full">
              <ContactCard
                icon={<Email />}
                title="Email"
                content="PayMate2024@gmail.com"
              />
              <ContactCard
                icon={<Phone />}
                title="Phone"
                content="3015188280"
              />
              <ContactCard
                icon={<Socials />}
                title="Socials"
                content={<SocialIcons />}
              />
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};

// Contact Header Component
const ContactHeader = () => {
  return (
    <div className="flex flex-col gap-4 items-start justify-center self-stretch shrink-0 relative">
      <div
        className="bg-foundation-green-green-100 rounded-[10px] pt-1.5 pr-2 pb-1.5 pl-2 flex flex-row gap-0.5 items-center justify-center shrink-0 relative overflow-hidden"
        style={{ boxShadow: "0px 1px 2px 0px rgba(105, 81, 255, 0.05)" }}
      >
        <div className="text-foundation-grey-grey-500 text-center font-text-xs-medium-font-family text-text-xs-medium-font-size leading-text-xs-medium-line-height font-text-xs-medium-font-weight relative">
          CONTACT
        </div>
      </div>
      <div
        className="text-foundation-grey-grey-500 text-left font-['ReemKufi-Bold',_sans-serif] text-3xl md:text-4xl font-bold relative"
        style={{ letterSpacing: "-0.02em" }}
      >
        Let’s stay connected
      </div>
      <div className="text-foundation-grey-grey-300 text-left font-['ReemKufi-Medium',_sans-serif] text-xl leading-[30px] font-medium relative self-stretch">
        It's never been easier to get in touch with Flex. Call us, use our live
        chat widget, or email, and we’ll get back to you as soon as possible!
      </div>
    </div>
  );
};

// Placeholder for SVG Icons
const Email = () => {
  return <img src={EmailIcon} alt="email"></img>;
};

const Phone = () => {
  return <img src={PhoneIcon} alt="phone"></img>;
};

const Socials = () => {
  return <img src={SocialIcon} alt="social"></img>;
};

// SocialIcons Component
const SocialIcons = () => {
  return (
    <div className="flex space-x-4">
      <a
        href="https://www.facebook.com/share/1ALFjooeEByvPfyc/?mibextid=LQQJ4d"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={FacebookIcon} alt="facebook" />
      </a>
      <a
        href="https://www.instagram.com/paym8_services?igsh=MWQ1aWs0MHlsN21maw=="
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={InstagramIcon} alt="instagram"></img>
      </a>
    </div>
  );
};

export default ContactSection;
