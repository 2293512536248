import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Slide } from "react-awesome-reveal";
import appStore from "../assets/app-store.svg";
import googlePlay from "../assets/google-play.svg";
import mobileDownload from "../assets/mobile-download.svg";

const DownloadAppSection = () => {
  return (
    <div className="mt-48 px-4 sm:px-10 h-auto lg:h-[536px] bg-foundation-grey-grey-500">
      <div className="max-w-7xl w-full mx-auto h-full">
        <div className="grid lg:grid-cols-2 justify-center items-center gap-10 relative h-full my-10 lg:my-0">
          <Slide direction="up" triggerOnce duration={1000} delay={100}>
            <div className="flex flex-col gap-8 items-start justify-start my-auto">
              <div className="flex flex-col gap-6 items-start justify-start self-stretch shrink-0 mt-8 lg:mt-0 relative">
                <div className="self-stretch shrink-0 h-auto static">
                  <div
                    className="text-foundation-grey-grey-50 text-left font-bold text-3xl md:text-4xl md:leading-[38px] left-0 top-0 w-full"
                    style={{ fontFamily: "ReemKufiInk-Bold, sans-serif" }}
                  >
                    Download Our App For Free
                  </div>
                </div>
                <div
                  className="text-[#ffffff] text-left text-lg leading-[29px] font-normal relative self-stretch h-auto"
                  style={{ fontFamily: "ReemKufiInk-Regular, sans-serif" }}
                >
                  Experience the convenience and power of PayM8—completely free!
                  Download our app today and unlock a suite of features designed
                  to simplify your financial life. Whether you're lending to
                  friends, paying for services, or managing scheduled transfers,
                  PayM8 is here to help. Available on both iOS and Android.
                </div>
              </div>

              <div className="flex flex-wrap gap-y-4 gap-x-8">
                <button className="flex items-center transition-all font-semibold rounded-md">
                  <img src={appStore} alt="appStore" />
                </button>
                <button className="bg-transparent flex items-center transition-all font-semibold rounded-md">
                  <img src={googlePlay} alt="googlePlay" />
                </button>
              </div>
            </div>
          </Slide>

          <Slide
            direction="up"
            triggerOnce
            duration={1000}
            delay={100}
            className="lg:h-[35rem]"
          >
            <div className="py-0 px-5 flex flex-row items-center">
              <div className="shrink-0 w-full static">
                <LazyLoadImage
                  alt="mobile"
                  src={mobileDownload}
                  effect="blur"
                  className="lg:-translate-y-24"
                  wrapperClassName="w-full h-full"
                />
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppSection;
