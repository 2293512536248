import React from "react";
import { Slide } from "react-awesome-reveal";
import HowItWorksStep from "./HowItWorksStep";
import SeeHowItWorks1 from "../assets/see-how-it-works1.png";
import SeeHowItWorks2 from "../assets/see-how-it-works2.png";
import SeeHowItWorks3 from "../assets/see-how-it-works3.png";
import SeeHowItWorks4 from "../assets/see-how-it-works4.png";

const HowItWorks = () => {
  return (
    <div className="mt-28 px-4 sm:px-10">
      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-col flex-wrap gap-2 my-5 items-start relative">
          <div
            className="bg-foundation-green-green-100 rounded-[10px] pt-1.5 pr-2 pb-1.5 pl-2 flex flex-row gap-0.5 items-center justify-center w-[173px] left-0 top-0 overflow-hidden"
            style={{ boxShadow: "0px 1px 2px 0px rgba(105, 81, 255, 0.05)" }}
          >
            <div className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Regular',_sans-serif] text-base leading-6 font-normal">
              HOW IT WORKS
            </div>
          </div>
          <div className="text-foundation-grey-grey-500 text-left font-['ReemKufiInk-Bold',_sans-serif] text-3xl md:text-4xl md:leading-[38px] font-bold left-0 top-[42px]">
            How Does PayM8 Work?
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 items-start">
          <Slide direction="up" triggerOnce duration={1000} delay={100}>
            <HowItWorksStep
              imageSrc={SeeHowItWorks1}
              stepNumber="1"
              title="Create Your Loan"
              description="Invite a friend, family member, or trusted contact to initiate a loan. Within minutes, you can set up the loan terms, get the agreement signed, and start tracking."
            />
          </Slide>
          <Slide direction="up" triggerOnce duration={1000} delay={200}>
            <HowItWorksStep
              imageSrc={SeeHowItWorks2}
              stepNumber="2"
              title="Easy Tracking"
              description="Seamlessly monitor payments and loan statuses with our intuitive tracking system. Say goodbye to awkward reminders and hello to organized, transparent financial interactions."
            />
          </Slide>
          <Slide direction="up" triggerOnce duration={1000} delay={300}>
            <HowItWorksStep
              imageSrc={SeeHowItWorks3}
              stepNumber="3"
              title="Pay for Services"
              description="Use PayM8 to easily pay for various services, from car repairs to lawn mowing. Receive and send invoices, keeping all transactions documented and accessible."
            />
          </Slide>
          <Slide direction="up" triggerOnce duration={1000} delay={400}>
            <HowItWorksStep
              imageSrc={SeeHowItWorks4}
              stepNumber="4"
              title="Scheduled Financial Arrangements"
              description="Set up recurring transfers for regular financial arrangements like biweekly or monthly payments. Manage and track all your financial commitments effortlessly."
            />
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
