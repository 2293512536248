import React from "react";
import { Slide } from "react-awesome-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import appStore from "../assets/app-store.svg";
import googlePlay from "../assets/google-play.svg";
import paymateHome from "../assets/paymate-home.svg";

const Description = () => {
  return (
    <div className="lg:min-h-[660px] px-4 sm:px-10">
      <div className="max-w-7xl w-full mx-auto py-6 h-full">
        <div className="grid lg:grid-cols-2 justify-center items-center gap-10 h-full lg:min-h-[660px]">
          <Slide direction="up" triggerOnce duration={1000}>
            <div>
              <h1 className="md:text-5xl text-4xl font-bold mb-6 md:!leading-[55px]">
                <span className="_3-n-1-one-stop-shop-for-financial-services-span">
                  3-N-1 One Stop Shop for&#160;
                </span>
                <span className="_3-n-1-one-stop-shop-for-financial-services-span2">
                  Financial Services
                </span>
              </h1>
              <p className="text-base leading-relaxed">
                PayM8 empowers you to manage loans, pay for services, and set up
                financial arrangements with ease and transparency. Lend money to
                friends and family, pay for services like car repairs or lawn
                mowing, and schedule recurring transfers with just a few taps.
              </p>
              <div className="flex flex-wrap gap-y-4 gap-x-8 mt-8">
                <button className="flex items-center transition-all font-semibold rounded-md">
                  <img src={appStore} alt="appStore" />
                </button>
                <button className="bg-transparent flex items-center transition-all font-semibold rounded-md">
                  <img src={googlePlay} alt="googlePlay" />
                </button>
              </div>
            </div>
          </Slide>

          <div className="relative max-lg:mt-12 h-full">
            <div className="absolute inset-0 flex justify-center items-center min-h-full lg:min-h-[660px]">
              {/* Circle 1 */}
              <div className="rounded-full border-solid border-foundation-green-green-600 border-[0.64px] w-[45%] h-[40%] absolute"></div>
              {/* Circle 2 */}
              <div className="rounded-full border-solid border-[#b7f42c] border-[0.64px] w-[57%] h-[53%] absolute"></div>
              {/* Circle 3 */}
              <div className="rounded-full border-solid border-[#c0f548] border-[0.64px] w-[70%] h-[64%] absolute"></div>
              {/* Circle 4 */}
              <div className="rounded-full border-solid border-[#cef66e] border-[0.64px] w-[81%] h-[76%] absolute"></div>
              {/* Circle 5 */}
              <div className="rounded-full border-solid border-[#d7fd86] border-[0.64px] w-[96%] h-[88%] absolute"></div>
            </div>

            <Slide direction="up" triggerOnce duration={1000} delay={300}>
              <div className="relative z-10 max-lg:mt-12 h-full">
                <LazyLoadImage
                  alt="paymate home"
                  src={paymateHome}
                  effect="blur"
                  className="w-full h-full object-cover"
                  wrapperClassName="w-full h-full"
                />
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
