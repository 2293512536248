import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import PaymateLogoLight from "../assets/paymate-logo-light.svg";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigation = (path, section) => {
    if (location.pathname === path) {
      scroller.scrollTo(section, {
        smooth: true,
        offset: -50,
        duration: 500,
      });
    } else {
      navigate(path, { state: { scrollToSection: section } });
    }
  };

  return (
    <header className="py-4 px-4 sm:px-10 bg-white z-50 relative">
      <div className="max-w-7xl w-full mx-auto flex flex-wrap items-center gap-4">
        <div className="w-auto h-[46px]">
          <a className="flex items-baseline" href="/">
            <img src={PaymateLogoLight} alt="logo" className="w-auto h-full" />
          </a>
        </div>

        <div
          id="collapseMenu"
          className={`${
            menuOpen ? "block" : "hidden lg:block"
          } max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-4 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50`}
        >
          <button
            id="toggleClose"
            className="lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white p-3"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 fill-black"
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>
          </button>

          <ul className="lg:ml-12 lg:flex gap-x-6 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">
            <li className="max-lg:border-b max-lg:pb-4 px-3 lg:hidden">
              <div
                className="flex items-baseline cursor-pointer"
                onClick={() => handleNavigation("/", "top")}
              >
                <img
                  src={PaymateLogoLight}
                  alt="logo"
                  className="w-auto h-full"
                />
              </div>
            </li>
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <div
                onClick={() => handleNavigation("/", "home")}
                className="hover:text-foundation-green-green-500 text-foundation-grey-grey-500 block font-semibold cursor-pointer transition-all"
              >
                Home
              </div>
            </li>
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <div
                onClick={() => handleNavigation("/", "how-it-works")}
                className="hover:text-foundation-green-green-500 text-foundation-grey-grey-500 block font-semibold cursor-pointer transition-all"
              >
                How it works?
              </div>
            </li>
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <div
                onClick={() => handleNavigation("/", "features-description")}
                className="hover:text-foundation-green-green-500 text-foundation-grey-grey-500 block font-semibold cursor-pointer transition-all"
              >
                3-N-1 One Stop
              </div>
            </li>
            <li className="max-lg:border-b max-lg:py-2 px-3">
              <div
                onClick={() => handleNavigation("/", "contact")}
                className="hover:text-foundation-green-green-500 text-foundation-grey-grey-500 block font-semibold cursor-pointer transition-all"
              >
                Contact Us
              </div>
            </li>
          </ul>
        </div>

        <div className="flex ml-auto">
          <button className="bg-foundation-green-green-500 text-foundation-grey-grey-500 flex items-center transition-all font-semibold rounded-md px-5 py-3">
            <div
              onClick={() => handleNavigation("/", "contact")}
              className="hover:text-foundation-grey-grey-300 text-foundation-grey-grey-500 block font-semibold cursor-pointer transition-all"
            >
              Contact Us
            </div>
          </button>
          <button
            id="toggleOpen"
            className="lg:hidden ml-auto rounded-full bg-white p-3"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 fill-black"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M3 7.5C3 6.67 3.67 6 4.5 6h15c.83 0 1.5.67 1.5 1.5S20.33 9 19.5 9h-15C3.67 9 3 8.33 3 7.5zm0 4.5c0-.83.67-1.5 1.5-1.5h15c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-15C3.67 13.5 3 12.83 3 12zm1.5 6c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h15c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-15z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
